"use client";

import { useState } from "react";
import { z } from "zod";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { Box, Typography, TextField, Link, Stack } from "@mui/material";
import { Button, Icon } from "@/shared/components";
import AuthDataApi from "../api/auth.api";
import { AuthType } from "../types";
import colors from "@/lib/palette";

type Props = {
  email: string;
  setEmail: (email: string) => void;
  setAuthType: (authType: AuthType) => void;
};

const resetPasswordSchema = z.object({
  email: z
    .string()
    .email("Invalid email address")
    .nonempty("Email is required"),
});

type ResetPasswordFormData = z.infer<typeof resetPasswordSchema>;

const ResetPassword = (props: Props) => {
  const { setAuthType, setEmail } = props;

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    resolver: zodResolver(resetPasswordSchema),
  });

  const onSubmitHandler: SubmitHandler<ResetPasswordFormData> = async (
    form
  ) => {
    setLoading(true);

    try {
      await AuthDataApi.resetPassword(form);
      setEmail(form.email);

      toast.success(
        "We've sent you an email with OTP code to reset your password",
        {
          icon: () => <Icon icon="success-message" width={32} height={32} />,
        }
      );
      setAuthType("resetConfirmation");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        Forgot your password?
      </Typography>

      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={4}
        marginTop={1}
      >
        Enter your email to reset a password
      </Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={1}>
          <TextField
            placeholder="Email*"
            variant="filled"
            disabled={loading}
            {...register("email")}
            type="text"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Box>

        <Button
          type="submit"
          disabled={loading}
          loading={loading}
          sx={{ marginTop: 3 }}
        >
          Submit
        </Button>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="8px"
          sx={{
            mt: "32px",
          }}
        >
          <Typography variant="body2">Back to</Typography>

          <Link
            component="button"
            variant="body2"
            sx={{
              color: colors.blue,
              fontWeight: 700,
              textDecoration: "none",
            }}
            onClick={() => setAuthType("signIn")}
          >
            Sign In
          </Link>
        </Stack>
      </Box>
    </Box>
  );
};

export default ResetPassword;
