"use client";

import Link from "next/link";
import { useAtom } from "jotai";
import { useState } from "react";
import { Box, Menu, MenuItem, SxProps, Tooltip } from "@mui/material";
import { StoreType } from "@/store/user.store";
import { triggerAtom } from "@/store/trigger.store";

import { Icon, Button } from "@/shared/components";
import colors from "@/lib/palette";
import HeaderButton from "./header-button";

type Props = {
  store: StoreType;
  onOpenModal: () => void;
  onLogout: () => void;
};

const defaultButtonStyles: SxProps = {
  width: {
    xs: "36px",
    lg: "auto",
  },
  paddingX: {
    xs: "10px",
    lg: "14px",
    xl: "16px",
  },
};

function DesktopButtonsComponent({ store, onOpenModal, onLogout }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setTrigger] = useAtom<boolean>(triggerAtom);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (store.isAuth) {
      setAnchorEl(event.currentTarget);
    } else {
      onOpenModal();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirectToLibrary = () => {
    const timeout = setTimeout(() => {
      setTrigger(true);

      clearTimeout(timeout);
    }, 500);
  };

  return (
    <Box
      component="nav"
      display="flex"
      columnGap={1}
      marginLeft={{
        xs: "auto",
      }}
    >
      <Tooltip title="Library">
        <Link
          href="/library?type=resources&page=1&pageCount=12"
          style={{ display: "block" }}
          onClick={handleRedirectToLibrary}
        >
          <HeaderButton
            icon="library"
            text="Library"
            sx={defaultButtonStyles}
          />
        </Link>
      </Tooltip>

      <Tooltip title="Technologies">
        <Link href="/technologies" style={{ display: "block" }}>
          <HeaderButton
            icon="categories"
            text="Technologies"
            sx={defaultButtonStyles}
          />
        </Link>
      </Tooltip>

      {/* <Tooltip title="For Business">
        <Link href="/business" style={{ display: "block" }}>
          <HeaderButton
            icon="business"
            text="For Business"
            sx={{
              width: {
                xs: "36px",
                lg: "150px",
                xl: "174px",
              },
              paddingX: {
                xs: "10px",
                lg: "14px",
                xl: "16px",
              },
            }}
          />
        </Link>
      </Tooltip> */}

      <Box
        sx={{
          flex: "1 0 auto",
          pointerEvents: "none",
          position: "relative",

          "&:before": {
            content: '""',
            position: "absolute",
            background: `url("/icons/coming_soon_default.png") no-repeat center/contain`,
            top: {
              lg: "-7px",
              xl: "-8px",
            },
            right: {
              lg: "-4px",
              xl: "-5px",
            },
            zIndex: 1,
            width: {
              lg: "50px",
              xl: "60px",
            },
            height: {
              lg: "50px",
              xl: "60px",
            },
          },

          "&:hover:before": {
            background: `url("/icons/coming_soon_hover.png") no-repeat center/contain`,
          },
        }}
      >
        <HeaderButton
          icon="business"
          text="For Business"
          sx={{
            opacity: 0.5,
            width: {
              xs: "36px",
              lg: "150px",
              xl: "174px",
            },
            paddingX: {
              xs: "10px",
              lg: "14px",
              xl: "16px",
            },
          }}
        />
      </Box>

      <Tooltip title={store.isAuth ? "Profile" : "Login"}>
        <Box>
          <Button
            variant="filled"
            sx={{
              ml: "8px",
              flex: "1 0 auto",
              padding: "0px",
              height: {
                xs: "36px",
                lg: "48px",
                xl: "60px",
              },
              width: {
                xs: "36px",
                lg: "48px",
                xl: "60px",
              },
            }}
            onClick={handleClick}
          >
            {store.isAuth ? (
              `${store.user?.first_name[0]} ${store.user?.last_name[0]}`
            ) : (
              <Icon
                icon="user"
                width={{ xs: 16, lg: 24 }}
                height={{ xs: 16, lg: 24 }}
                color={colors.white}
              />
            )}
          </Button>
        </Box>
      </Tooltip>

      {store.isAuth && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            ml: "8px",
          }}
        >
          <Link href="/profile">
            <MenuItem onClick={handleClose}>Profile</MenuItem>
          </Link>
          <MenuItem onClick={onLogout}>Log out</MenuItem>
        </Menu>
      )}
    </Box>
  );
}

export default DesktopButtonsComponent;
