import { z } from "zod";
const nameRegex = /^[A-Za-z' -]+$/;

export const lastNameValidation = z
  .string()
  .trim()
  .nonempty("Last Name is required")
  .regex(nameRegex, "Invalid input: only letters, spaces, - or ' allowed")
  .max(50, "Last Name must not exceed 50 characters")
  .refine((val) => !val.startsWith(" "), {
    message: "Last Name cannot start with space",
  })
  .refine((val) => val.trim().length >= 2, {
    message: "Last Name should be at least 2 characters",
  });
