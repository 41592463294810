import { z } from "zod";
export const nameRegex = /^[A-Za-z' -]+$/;

export const firstNameValidation = z
  .string()
  .trim()
  .nonempty("First Name is required")
  .max(50, "First Name must not exceed 50 characters")
  .regex(nameRegex, "Invalid input: only letters, spaces, - or ' allowed")
  .refine((val) => !val.startsWith(" "), {
    message: "First Name cannot start with space",
  })
  .refine((val) => val.trim().length >= 2, {
    message: "First Name should be at least 2 characters",
  });
