"use client";

import { useState } from "react";
import { z } from "zod";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { Button, Icon, PasswordInput } from "@/shared/components";
import AuthDataApi from "../api/auth.api";
import { AuthType } from "../types";

const newPasswordSchema = z
  .object({
    new_password: z
      .string()
      .min(8, { message: "Password should be more than 8 characters" })
      .regex(/[A-Z]/, {
        message: "Password should contain an uppercase letter",
      })
      .regex(/[a-z]/, { message: "Password should contain a lowercase letter" })
      .regex(/[!@#$%^&*]/, { message: "Password should contain a symbol" }),
    confirm_new_password: z.string(),
  })
  .refine((data) => data.new_password === data.confirm_new_password, {
    message: "Passwords do not match",
    path: ["confirm_new_password"],
  });

type NewPasswordFormData = z.infer<typeof newPasswordSchema>;
type Props = {
  email: string;
  otpCode: string;
  setEmail: (email: string) => void;
  setAuthType: (authType: AuthType) => void;
};
const SetNewPassword = ({ email, otpCode, setAuthType }: Props) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<NewPasswordFormData>({
    resolver: zodResolver(newPasswordSchema),
  });

  const new_password = watch("new_password");

  const onSubmitHandler: SubmitHandler<NewPasswordFormData> = async (form) => {
    setLoading(true);

    try {
      await AuthDataApi.createNewPassword({
        confirm_new_password: form.confirm_new_password,
        new_password: form.new_password,
        otp_code: otpCode,
        email,
      });

      toast.success("Your password was updated successfully", {
        icon: () => <Icon icon="success-message" width={32} height={32} />,
      });

      setAuthType("signIn");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center">
        Create new password
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        marginBottom={4}
        marginTop={2}
      >
        Password should be more than 8 characters, consist of uppercase and
        lowercase letters and symbols like %, &, $, #...
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)}>
        <Box display="grid" gridTemplateColumns="1fr" rowGap={1}>
          <PasswordInput
            passwordType="secondary"
            id="outlined-adornment-password-new"
            placeholder="New password*"
            disabled={isSubmitting}
            {...register("new_password")}
            value={new_password}
            onChange={(e) => setValue("new_password", e.target.value)}
            error={!!errors.new_password}
            helperText={errors.new_password?.message}
          />

          <PasswordInput
            id="outlined-adornment-password-confirm"
            placeholder="Confirm a new password*"
            disabled={isSubmitting}
            {...register("confirm_new_password")}
            error={!!errors.confirm_new_password}
            helperText={errors.confirm_new_password?.message}
          />
        </Box>
        <Button
          type="submit"
          disabled={loading}
          loading={loading}
          sx={{ marginTop: 3 }}
        >
          Set up new password
        </Button>
      </Box>
    </Box>
  );
};

export default SetNewPassword;
